<template>
    <div class="loadMore">
        <vue-data-loading
                :loading="loading"
                :completed="completed"
                :offset="-1"
                :listens="['infinite-scroll', 'pull-down']"
                :init-scroll="false"
                @infinite-scroll="infiniteScroll"
                @pull-down="pullDown">
            <div>
                <slot></slot>
            </div>
            <div slot="infinite-scroll-loading">loading...</div>
            <div slot="completed">到底啦__</div>
        </vue-data-loading>
    </div>
</template>
 
<script>
    import VueDataLoading from 'vue-data-loading'
    export default {
        name: 'loadMore',
        props:{
            completed:{ //当设置为 true 时将不会再触发 infinite-scroll 事件.当所有数据全部加载完毕后，建议设置为 true
                type:Boolean,
                default:false
            },
			loading:{ //当 loading 为 true 时将不会触发任何事件, 所以你可以使用它来控制加载数据.
			    type:Boolean,
			    default:false
			}
        },
        components: {
            VueDataLoading
        },
        data() {
            return {
                page: 1,
                isBack:false
            }
        },
        methods: {
            pullDown() {
                this.page = 1
                this.$emit('changeData',1)
            },
            infiniteScroll() {
                //到底触发的事件
                this.page++;
                this.$emit('changeData',this.page)
            },
        }
    }
</script>