<template>
	<div class="">
		<div class="list-items padding-top-20 flex margin-bottom point" @click="toDetail">
			<div class="book-cover margin-right-sm">
				<img :src="item.thumb | filterArray">
				<div class="ftag fs12" :class="item.is_free==1 ? 'free_tag' :'fee_tag' ">
					{{item.is_free==1 ? '免费' : '收费'}}
				</div>
			</div>
			<div class="right-info flex flex-direction  justify-around">
				<div class="flex align-center title-box">
					<p class="book-title text-cut">{{item.title}}</p>
					<div class="ftag fs12" :class="item.is_continued==1 ? 'free_tag' :'fee_tag' ">
						{{item.is_continued==1 ? '连载' : '完结'}}
					</div>
				</div>
				<div class="flex align-center writer-info">
					<div class="" style="flex-shrink: 0;">
						<div class="head-box">
							<el-image :src="item.user.head_img" class="head-img margin-right-xs radius-48 block-48" fit="cover"></el-image>
							<img src="../../assets/image/icon/bigv.png" class="block-20 bigv" v-if="item.user.is_v==1">
						</div>
						<!-- <img :src="item.user.head_img" class="block-48 radius-48 margin-right-xs"> -->
					</div>
					<div class="left-info">
						<p class="name">{{item.writer_profile}}</p>
						<div class="writer-tag flex align-center margin-top-5" v-if="item.user && item.user.tags_info && item.user.tags_info.length>0 && item.user.tags_info[0]!=''">
							<p type="warning" v-if="j<2" size="mini" class="margin-right-5" v-for="(ele,j) in item.user.tags_info" :key="j">{{ele}}</p>
						</div>
					</div>
				</div>
				<div class="flex align-center">
					<img src="../../assets/image/icon/push.png" class="block-18 margin-right-10">
					<p class="fs12">发布时间：{{item.create_time}}</p>
				</div>
				<el-row :gutter="24">
					<el-col :span="8">
						<div class="point flex align-center">
							<div class="flex align-center" slot="reference">
								<img src="../../assets/image/home/share.png" class="block-20 margin-right-5">
								<span class="fs14 text-999">{{item.transmit_num || 0}}</span>
							</div>
						</div>
					</el-col>
					<el-col :span="8">
						<div class="point flex align-center">
							<img src="../../assets/image/icon/view.png" class="block-20 margin-right-5">
							<span class="fs14 text-999" >{{item.read_num || 0}}</span>
						</div>
					</el-col>
					<el-col :span="8">
						<div class="grid-content bg-purple">
							<div class="fs12 flex align-center">
								<img src="../../assets/image/home/zan1.png" class="block-20 margin-right-5" v-if="item.is_like==1" key="like">
								<img src="../../assets/image/home/zan.png" class="block-20 margin-right-5" v-else key="unlike">
								<!-- <img src="../../assets/image/home/like.png" class="block-16 margin-right-5"> -->
								<span :class="item.is_like==1 ? 'text-theme' : 'text-999' ">{{item.like_num || 0}}</span>
							</div>
						</div>
					</el-col>
					<!-- <el-col :span="8">
						<div class="point flex align-center" >
							<img src="../../assets/image/home/message.png" class="block-20 margin-right-5">
							<span class="fs14 text-999" >{{item.comm_num || 0}}</span>
						</div>
					</el-col> -->
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			item:{
				type:Object,
				default:{}
			}
		},
		data() {
			return {
				
			}
		},
		filters:{
			filterArray(val){
				return val[0]
			}
		},
		methods:{
			toDetail(){
				this.toBlank({
					path:'/fictionDetail',
					query:{
						aid:this.item && this.item.aid
					}
				})
				// this.$router.push({
				// 	path:'/fictionDetail',
				// 	query:{
				// 		aid:this.item && this.item.aid
				// 	}
				// })
			},
			// 收藏
			toCollect(){	
				this.ajax('post', '/v1/6141a0f6bd7d0', {
					article_id: this.item.aid,
					type:1
				}, res => {
					if (res.code == 1) {
						this.item.is_collect = this.item.is_collect ==1 ? 0 : 1;
						if(this.item.is_collect ==1){
							this.item.collect_num +=1
						}else{
							this.item.collect_num -=1
						}
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 点赞
			giveLike(){
				this.ajax('post', '/v1/61419389e89d5', {
					article_id: this.item.aid
				}, res => {
					if (res.code == 1) {
						this.item.is_like = this.item.is_like ==1 ? 0 : 1;
						if(this.item.is_like ==1){
							this.item.like_num +=1
						}else{
							this.item.like_num -=1
						}
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.book-cover {
		width: 104px;
		height: 146px;
		background: rgba(0, 0, 0, 0);
		opacity: 1;
		border-radius: 8px 0px 0px 8px;
		position: relative;
		flex-shrink: 0;
		img {
			width: 100%;
			height: 100%;
			border-radius: 8px 0px 0px 8px;
		}
		.ftag {
			position: absolute;
			top: 0;
			right: 0;
			width: 36px;
			text-align: center;
			height: 20px;
			line-height: 20px;
			border-radius: 0 0 0 10px;
			font-weight: bold;
		}
		
		.free_tag {
			color: #31CBA4;
			background: #CAF4D7;
		}
		
		.fee_tag {
			color: #FF7F37;
			background: #FDEDEB;
		}
	}
	.head-box{
		position: relative;
		.bigv{
			position: absolute;
			bottom: 5px;
			right: 5px;
		}
	}
	.right-info {
		height: 146px;
		.title-box{
			.ftag {
				margin-left: 10px;
				width: 36px;
				text-align: center;
				height: 20px;
				line-height: 20px;
				border-radius: 0 0 0 10px;
				font-weight: bold;
			}
			
			.free_tag {
				color: #31CBA4;
				background: #CAF4D7;
			}
			
			.fee_tag {
				color: #FF7F37;
				background: #FDEDEB;
			}
		}
	}

	.book-title {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		opacity: 1;
	}

	.writer-info {
		.head-img {
			width: 29px;
			height: 29px;
		}

		.left-info {
			.name {
				font-size: 11px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #333333;
				opacity: 1;
			}
		}

		.writer-tag {
			p {
				width: 66px;
				height: 20px;
				text-align: center;
				opacity: 1;
				font-size: 8px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 20px;
				color: #FFFFFF;
				opacity: 1;
				&:nth-child(1){
					background: url(../../assets/image/icon/label-bg1.png) no-repeat;
					background-size: cover;
				}
				&:nth-child(2){
					background: url(../../assets/image/icon/label-bg2.png) no-repeat;
					background-size: cover;
				}
			}
		}
	}
</style>
